import React from "react";
import { Box, Typography, List, ListItem, Avatar } from "@mui/material";

const About = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ textAlign: "center", marginBottom: 4 }}>
        <Typography variant="h3">Team</Typography>
        <Avatar
          src="/JenPic.jpg"
          alt="Jennifer Cantu, Licensed Professional Counselor"
          sx={{
            width: 300,
            height: 350, // Ensure the avatar is square
            margin: "auto",
          }}
        />
        <Typography variant="h6" color="inherit" sx={{ fontWeight: "bold" }}>
          She/Her
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Jennifer Cantu
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Founder, LPC
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center", marginTop: 2 }}>
          I received my Bachelor of Arts in Psychology at Regis University in
          Denver, Co., where I minored in sociology and played collegiate
          soccer. I continued to pursue a Master of Arts in Counseling in my
          hometown at The University of Texas in San Antonio. I have 10 years of
          experience working with children, adolescents and their families in a
          variety of settings. Including in their homes, schools, hospitals and
          treatment programs.
          <br />
          <br />I have spent the last 3 years focused on family counseling,
          which encompasses relationship issues, communication issues, divorce,
          blending families and co-parenting. I practice with a lens that
          incorporates the client's worldview, including all the vital moving
          parts and relationships in their lives that contribute to their unique
          situation.
          <br />
          <br />I have a special interest in working with children who struggle
          with behavioral issues, teens struggling with social and emotional
          issues, couples and families. Being a mother of five children, I hold
          a space in my heart for mothers struggling with pre/postpartum issues.
          I firsthand understand the demands our children and teens are facing
          with school and extracurricular activities. The pressure of performing
          in sports, in the classroom and socially can be overwhelming. I am a
          fan of extracurricular activities, especially for teens. Whether it be
          band or football, I have found that having a built-in support group
          and responsibilities fosters a sense of pride and belonging. Not to
          mention eliminating idle time that, for some, can result in poor
          choices. At the same time, balancing grades and schedules can be a
          challenge, and it is important for parents and kids to recognize when
          we may be overscheduled and simply need some self care and grace.
          <br />
          <br />
          In my free time I enjoy attending my children’s numerous activities
          and my wonderful women’s class at Crossfit Central. My husband and I
          enjoy traveling, attending sporting events, shuttling our kids to
          their activities and spending time with our friends and family.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h3" sx={{ textAlign: "center", marginTop: 2 }}>
          Treatment Approach
        </Typography>
        <List sx={{ textAlign: "center" }}>
          <ListItem sx={{ justifyContent: "center" }}>
            Attachment-Based
          </ListItem>
          <ListItem sx={{ justifyContent: "center" }}>
            Cognitive Behavioral (CBT)
          </ListItem>
          <ListItem sx={{ justifyContent: "center" }}>
            Compassion Focused
          </ListItem>
          <ListItem sx={{ justifyContent: "center" }}>
            Culturally Sensitive
          </ListItem>
          <ListItem sx={{ justifyContent: "center" }}>
            Dialectical Behavior (DBT)
          </ListItem>
          <ListItem sx={{ justifyContent: "center" }}>Family Systems</ListItem>
          <ListItem sx={{ justifyContent: "center" }}>Interpersonal</ListItem>
          <ListItem sx={{ justifyContent: "center" }}>Person-Centered</ListItem>
          <ListItem sx={{ justifyContent: "center" }}>
            Play Therapy/ Sand Tray Therapy
          </ListItem>
          <ListItem sx={{ justifyContent: "center" }}>
            Positive Psychology
          </ListItem>
          <ListItem sx={{ justifyContent: "center" }}>Relational</ListItem>
          <ListItem sx={{ justifyContent: "center" }}>Strengths-Based</ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default About;

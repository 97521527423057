import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// Define a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#3e5b81", // A deep green, reminiscent of the natural surroundings
    },
    secondary: {
      main: "#F2A65A", // A warm orange, reflecting the sunset over the bridge
    },
    background: {
      default: "#F5F5F5", // A light grey for a clean, modern look
    },
    text: {
      primary: "#333333", // Dark grey for readability
      secondary: "#666666", // Lighter grey for secondary text
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "2.5rem",
      fontWeight: 700,
      color: "#3A6351",
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 600,
      color: "#3A6351",
    },
    h6: {
      color: "white", // Ensure h6 variant is white
    },
    body1: {
      fontSize: "1rem",
      color: "#333333",
    },
    body2: {
      fontSize: "0.875rem",
      color: "#666666",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
);

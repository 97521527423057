import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Typography, Container, Box } from "@mui/material";
import amberRoom from "../assets/images/amber_room.jpg";
import ivoryRoom from "../assets/images/ivory_room.jpg";
import onyxRoom from "../assets/images/onyx_room.jpg";
import amberChairs from "../assets/images/amber_chairs.jpg";
import waitingRoom from "../assets/images/waiting_room.jpg";

const headerStyle = {
  textAlign: "center",
  fontFamily: "Arial, sans-serif",
  fontWeight: "bold",
  color: "#333",
  margin: "20px 0",
};

const Offerings = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  const imageStyle = {
    width: "100%",
    maxWidth: "300px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    // transform: "rotate(-2deg)",
    margin: "20px auto",
  };

  const centeredBoxStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Container>
      <main>
        <Box id="child-adolescent-counseling" mb={4}>
          <Typography variant="h4" style={headerStyle}>
            Child and Adolescent Counseling
          </Typography>
          <Box style={centeredBoxStyle}>
            <Box
              component="img"
              src={ivoryRoom}
              alt="Child Counseling"
              style={imageStyle}
            />
          </Box>
          <Typography paragraph>
            We offer counseling for children starting at 3 years old. Young
            children may need support navigating big feelings and big emotions.
            Children at any age can learn coping skills and tools to help manage
            their emotions and behaviors. Some of these behaviors include
            emotional outbursts, aggression, negative self-talk, social issues,
            self esteem, anxiety, depression, ADHD, trauma, emotional regulation
            and struggling with sibling relationships. Other presenting factors
            are major life changes including divorce, blending families or the
            loss of a loved one. 360 Family Counseling encourages parent and
            caretaker involvement in the therapeutic process.
          </Typography>
          <Typography paragraph>
            For teens and young adults, counseling offers them a safe and
            confidential place to process and explore challenging issues.
            Presenting concerns range from anxiety, depression, self-harm,
            feeling overwhelmed and overscheduled with the demands of school and
            extracurricular activities, loss of interest, trauma, disordered
            eating, ADHD, emotional regulation and relationship issues. Virtual
            sessions are offered for all clients and utilized often with college
            students.
          </Typography>
        </Box>

        <Box id="individual-counseling" mb={4}>
          <Typography variant="h4" style={headerStyle}>
            Adult Individual Counseling
          </Typography>
          <Box style={centeredBoxStyle}>
            <Box
              component="img"
              src={onyxRoom}
              alt="Child Counseling"
              style={imageStyle}
            />
          </Box>
          <Typography paragraph>
            Individual counseling offers adults a place to process and explore
            the obstacles that are preventing them from feeling at peace. It can
            also be a place to set goals and untap strengths to aid in success.
            Individual counseling can be client lead or therapist directed.
            Together you and your therapist will explore a treatment plan that
            best fits your personal needs.
          </Typography>
        </Box>

        <Box id="couples-counseling" mb={4}>
          <Typography variant="h4" style={headerStyle}>
            Couples Counseling
          </Typography>
          <Box style={centeredBoxStyle}>
            <Box
              component="img"
              src={amberRoom}
              alt="Child Counseling"
              style={imageStyle}
            />
          </Box>
          <Typography paragraph>
            Pre-marital and couples counseling can provide a safe place to
            explore and strengthen relationships. Exploring our partners'
            communication and conflict style is a powerful tool for conflict
            resolution. These styles most likely differ and counseling can help
            identify these differences and offer an understanding of why
            conflicts tend to evolve into unhealthy fighting patterns. Exploring
            how one prefers to give and receive information offers partners
            insight into how to effectively communicate. We often fall into
            unproductive conflict habits and learning new skills and tools can
            aid in more productive conflict resolution.
          </Typography>
          <Typography paragraph>
            Other presenting issues are exploring forgiveness, making the
            difficult decision to stay together or separate, exploring family
            dynamics and family planning.
          </Typography>
        </Box>

        <Box id="family-counseling" mb={4}>
          <Typography variant="h4" style={headerStyle}>
            Family Counseling
          </Typography>
          <Box style={centeredBoxStyle}>
            <Box
              component="img"
              src={amberChairs}
              alt="Child Counseling"
              style={imageStyle}
            />
          </Box>
          <Typography paragraph>
            Family counseling can take on many forms. Presenting issues include
            strengthening the family unit, mending relationships, setting
            boundaries, communication issues, agreeing upon family rules,
            conflict resolution and mediation. 360 Family Counseling often
            encourages clients to invite family members to sessions when
            appropriate. Mending relationships can be healing. Family counseling
            can offer a safe place to communicate difficult or painful
            information to loved ones.
          </Typography>
        </Box>

        <Box id="parenting-co-parenting" mb={4}>
          <Typography variant="h4" style={headerStyle}>
            Parent Coaching and Co-Parenting
          </Typography>
          <Box style={centeredBoxStyle}>
            <Box
              component="img"
              src={waitingRoom}
              alt="Child Counseling"
              style={imageStyle}
            />
          </Box>
          <Typography paragraph>
            Parent coaching offers support to caregivers struggling with their
            children’s behavior. This can include unwanted behaviors, fighting
            with siblings, difficulty following expectations and boundaries with
            electronics. Parent coaching offers strategies and tools to use at
            home and offers parents support and validation in this difficult
            phase of life.
          </Typography>
          <Typography paragraph>
            Co-parenting is helpful for separated or divorced parents. Some
            separated parents have an amicable relationship while others remain
            in high conflict. What is important is that the children's emotional
            needs are met. Co-parenting counseling can help parents put the
            children's needs first and agree upon rules and boundaries. At 360
            Family Counseling it is common for the children to be in therapy and
            for the parents to participate in co-parenting sessions depending on
            the needs of the family.
          </Typography>
        </Box>
      </main>

      <footer>
        <Typography variant="body2" align="center">
          &copy; {new Date().getFullYear()} 360 Family Counseling. All rights
          reserved.
        </Typography>
      </footer>
    </Container>
  );
};

export default Offerings;

import React from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const Careers = () => {
  const navigate = useNavigate();

  const handleApplyNow = () => {
    navigate("/contact");
  };

  return (
    <Container>
      <Box my={4} display="flex" justifyContent="center">
        <Card sx={{ maxWidth: 600 }}>
          <CardContent>
            <Typography
              variant="h2"
              component="h2"
              gutterBottom
              sx={{ color: "inherit", textAlign: "center" }}
            >
              Careers
            </Typography>
            <Typography variant="h4" component="h4" gutterBottom>
              Hiring LPC and LPC Associates
            </Typography>
            <Typography variant="body1" paragraph>
              We are looking for Licensed Professional Counselors (LPC) and LPC
              Associates to join our team in Austin, Texas. Positions are
              available for both in-person and remote work.
            </Typography>
            <Typography variant="body1" paragraph>
              If you are an LPC Associate or a Licensed Professional Counselor,
              we encourage you to apply. This is a great opportunity to start
              your career in a supportive and dynamic environment. You will have
              the opportunity to grow in an environment that is supportive and
              collaborative.
            </Typography>
            <Typography variant="body1" paragraph>
              Location: Austin, Texas (In-person or Remote)
            </Typography>
            <Typography variant="body1" paragraph>
              Requirements:
            </Typography>
            <ul>
              <li>
                Master's degree or higher in one of the behavioral sciences from
                an accredited college or university
              </li>
              <li>Strong communication and interpersonal skills</li>
              <li>Ability to work independently and as part of a team</li>
            </ul>
            <Typography variant="body1" paragraph>
              To apply, please send your resume and cover letter to
              info@360familycounseling.com or click the link below to contact
              us.
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              onClick={handleApplyNow}
              fullWidth
            >
              Apply Now
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Container>
  );
};

export default Careers;

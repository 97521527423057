import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Home from "./Home";
import Team from "./About/Team";
import Blogs from "./Blogs";
import BlogPost from "./BlogPost"; // Import the BlogPost component
import Services from "./About/Services";
import Contact from "./Contact";
import Footer from "./Footer";
import Fees from "./About/Fees";
import { AppBar, Toolbar, Typography, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/system";
import JoinUs from "./About/Join_Us";

const Navbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const NavbarLink = styled(Link)({
  textDecoration: "none",
  color: "inherit",
});

const DropdownMenu = styled(Menu)({
  marginTop: "10px",
});

const App = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  let timeoutId = null;

  const handleMouseEnter = (event) => {
    clearTimeout(timeoutId);
    setAnchorEl(event.currentTarget);
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
      setIsDropdownOpen(false);
      setAnchorEl(null);
    }, 200); // Adjust the delay as needed
  };

  const handleItemClick = () => {
    setIsDropdownOpen(false);
    setAnchorEl(null);
  };

  const handleParentMouseLeave = () => {
    setIsDropdownOpen(false);
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <Router>
      <div className="parent-container" onMouseLeave={handleParentMouseLeave}>
        <AppBar position="static">
          <Navbar>
            <Typography variant="h6">
              <NavbarLink to="/">Home</NavbarLink>
            </Typography>
            <Typography variant="h6">
              <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <NavbarLink
                  to="#"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  sx={{ textDecoration: "none", color: "white" }}
                >
                  About Us
                </NavbarLink>
                {isDropdownOpen && (
                  <DropdownMenu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={isDropdownOpen}
                    onClose={handleMouseLeave}
                    MenuListProps={{
                      onMouseLeave: handleMouseLeave,
                    }}
                  >
                    <MenuItem onClick={handleItemClick}>
                      <NavbarLink to="/about/services">Services</NavbarLink>
                    </MenuItem>
                    <MenuItem onClick={handleItemClick}>
                      <NavbarLink to="/about/team">Team</NavbarLink>
                    </MenuItem>
                    <MenuItem onClick={handleItemClick}>
                      <NavbarLink to="/about/fees">Fees</NavbarLink>{" "}
                      {/* New Fees menu item */}
                    </MenuItem>
                    <MenuItem onClick={handleItemClick}>
                      <NavbarLink to="/about/join_us">Join Us</NavbarLink>
                    </MenuItem>
                  </DropdownMenu>
                )}
              </div>
            </Typography>
            <Typography variant="h6">
              <NavbarLink to="/blogs">Blogs</NavbarLink>
            </Typography>
            <Typography variant="h6">
              <NavbarLink to="/contact">Contact Us</NavbarLink>
            </Typography>
          </Navbar>
        </AppBar>
        <div style={{ paddingTop: "64px" }}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about/services" element={<Services />} />
            <Route path="/about/team" element={<Team />} />
            <Route path="/about/fees" element={<Fees />} />{" "}
            {/* New Fees route */}
            <Route path="/about/join_us" element={<JoinUs />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog/:id" element={<BlogPost />} />{" "}
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;

import React, { useState } from "react";
import emailjs from "emailjs-com";
import { TextField, Button, Typography, Container, Box } from "@mui/material";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailData = {
      to_email: "info@360360familycounseling.com",
      from_name: formData.name,
      from_email: formData.email,
      phone_number: formData.phone,
      message: formData.message,
    };
    emailjs
      .send(
        "service_ffou1b3",
        "template_r9j8mgs",
        emailData,
        "2wJThAeSyMFCYrWRk"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSubmitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Container maxWidth="sm">
      <Box display="flex" justifyContent="center">
        <Typography variant="h4" component="h2" gutterBottom>
          Contact Us
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
        <Typography variant="body1" fontWeight={"bold"}>
          4131 Spicewood Springs Road Building I Unit 4 Austin Texas 78759
        </Typography>
        <Typography variant="body1" fontWeight={"bold"}>
          info@360familycounseling.com
        </Typography>
        <Typography variant="body1" fontWeight={"bold"}>
          (737) 301-9800
        </Typography>
      </Box>
      {submitted ? (
        <Typography variant="body1">
          Thank you for contacting us! Someone will be in contact with you
          within 24 hours.
        </Typography>
      ) : (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            margin="normal"
            sx={{ backgroundColor: "white" }}
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
            margin="normal"
            sx={{ backgroundColor: "white" }}
          />
          <TextField
            fullWidth
            label="Phone Number"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            margin="normal"
            sx={{ backgroundColor: "white" }}
          />
          <TextField
            fullWidth
            label="Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            margin="normal"
            multiline
            rows={4}
            sx={{ backgroundColor: "white" }}
          />
          <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default Contact;

import React from "react";
import {
  Typography,
  Container,
  Card,
  CardContent,
  Button,
  Box,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";

const Fees = () => {
  const feeDetails = [
    { title: "50 minute sessions individual counseling", price: "$155" },
    { title: "50 minute sessions couples/family counseling", price: "$165" },
    { title: "90 minute sessions", price: "$245" },
  ];

  return (
    <Container>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: "inherit", textAlign: "center", paddingTop: 5 }}
      >
        Fees
      </Typography>
      <Grid container spacing={3}>
        {feeDetails.map((fee, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{ color: "inherit", textAlign: "center" }}
                >
                  {fee.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "inherit",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {fee.price}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Typography
        variant="body1"
        sx={{ marginTop: "20px", color: "inherit", textAlign: "center" }}
      >
        Although insurance is not accepted, we can provide a superbill for the
        client to submit to their insurance company.
      </Typography>
      <Box display="flex" justifyContent="center" sx={{ mt: 5 }}>
        <Button
          component={Link}
          to="/contact"
          variant="contained"
          color="primary"
          sx={{ padding: "10px 20px", fontSize: "1.1rem" }}
        >
          Contact Us
        </Button>
      </Box>
    </Container>
  );
};

export default Fees;

import React from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
} from "@mui/material";
import kidsAroundTable from "./assets/kids_around_table.png";
import coupleHiking from "./assets/images/couple_hiking.png";

const blogPosts = [
  {
    id: 1,
    title: "Supporting Young Children Through Divorce",
    author: "Jenny Cantu LPC",
    image: kidsAroundTable,
    content: (
      <>
        <Typography variant="body1" component="p" paragraph>
          In my work in the family counseling setting, I have learned some
          valuable tips in helping children navigate divorce. Sharing the news
          with children is never easy. Most parents have shared with me that it
          is one of the most difficult days of their lives. I often meet with
          parents who are looking for extra support for their children in this
          challenging time. Depending on age, there are different ways to
          support young children during this transition.
        </Typography>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          textAlign={"center"}
        >
          Play Therapy
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          I have found play therapy to be quite helpful with younger children.
          The sand tray is a great way to help children express themselves
          through play. It also gives the therapist a developmentally
          appropriate way to communicate with the child. Watching a child build
          a world and share what they are creating helps build self-esteem,
          independence and expression. Repeating what the child says during play
          is a developmentally appropriate way to communicate with them that you
          hear and empathize with what they are expressing. Building alongside
          them gives them a sense of community, ownership, creativity and
          freedom to express. Some of the play can be more directive as well.
          Using art therapy, doll houses and figurines can help illustrate these
          new family dynamics. This is helpful especially when the activity is
          repeated in following sessions to establish a sense of routine as well
          as support the new family dynamic. For children who are going to start
          transitioning between homes, it is a safe way to incorporate into
          play, two safe and loving homes. It is a good time to outline what
          will stay constant in their lives like school and caregivers. To play
          along these lines helps build safety for the child. The goal is to
          help the child learn that although their world is going to change,
          they are still loved and cared for. This type of therapy also gives
          children a safe place to ask the hard questions.
        </Typography>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          textAlign={"center"}
        >
          Parent Sessions
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          Not all separations look alike. Some families have a more contentious
          dynamic at play. Counseling for the parent and the child is
          beneficial. Co-parenting sessions in both amicable and contentious
          families is important. I have found that parents regardless of their
          situation can agree on one thing; “we want our child to be happy and
          healthy.” I like to coach parents to not add any emotional rocks into
          their child’s backpack. They are already navigating their new world
          and it is key to support them in positive ways. Things to be aware of
          include oversharing, arguing, having grown up discussions within ear
          shot, and other behaviors that we may not realize are weighing down
          that backpack. Some behaviors to look out for in the child may be
          stomachaches, separation anxiety, bed wetting/accidents, anxiety and
          other behavior changes. In counseling with children, I like to explore
          what I call big behaviors and big feelings. It is helpful for children
          to be able to identify what they are feeling and even learn some of
          the signs that lead to unwanted behaviors. For example, identifying
          when their cheeks feel hot, their heart beats faster or their
          breathing feels different. Having the words to tell a loved one that
          they are feeling mad, sad or worried is a healthy way to lighten their
          backpack so to speak. Learning strategies to self soothe or seek the
          space that they may need include breathing techniques, counting
          techniques and relaxation techniques. Skills that can help them in all
          aspects of life. I hope that you can find the right fit to support
          yourself and your child through this life change.
        </Typography>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          textAlign={"center"}
        >
          Other Tips
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body1" component="p">
              <strong>Transitions:</strong> Transitions between homes are
              easiest when the exchange is a school pickup/drop off. This helps
              avoid the emotional goodbyes.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" component="p">
              <strong>Personal Items:</strong> Having personal items at both
              homes. Making sure that special blanket/teddy makes the
              transition. Maybe have a lovey at both homes.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" component="p">
              <strong>Routine:</strong> Trying to keep similar schedules at both
              homes i.e., bedtime, screentime, activities.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" component="p">
              <strong>Talk:</strong> Check in with your child. Ask how they are
              doing, feeling and if they have any questions.
            </Typography>
          </ListItem>
        </List>
      </>
    ),
  },
  {
    id: 2,
    title: "Starting Couples Counseling",
    content:
      "Couples counseling can be a transformative experience for relationships facing challenges. This post delves into the key aspects of starting couples counseling, including how to choose the right therapist, setting realistic goals, and understanding the process to foster a healthier relationship...",
    image: coupleHiking,
    author: "Jenny Cantu LPC",
  },
  // Add more blog posts here
];

const BlogPost = () => {
  const { id } = useParams();
  const post = blogPosts.find((post) => post.id === parseInt(id));

  if (!post) {
    return <Typography variant="h5">Post not found</Typography>;
  }

  return (
    <Container style={{ display: "flex", justifyContent: "center" }}>
      <Card style={{ maxWidth: "800px", width: "100%" }}>
        <CardContent>
          {post.image && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={post.image}
                alt={post.title}
                style={{ width: "50%", height: "auto" }}
              />
            </div>
          )}
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            textAlign={"center"}
          >
            {post.title}
          </Typography>
          {post.content}
          <Typography
            variant="subtitle"
            component="h2"
            gutterBottom
            style={{ fontWeight: "bold", textAlign: "center" }}
          >
            Written By: {post.author}
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default BlogPost;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  Paper,
  useTheme,
  Grid,
} from "@mui/material";
import { styled } from "@mui/system";
import "./index.css";
import { useMediaQuery } from "@mui/material";

const Footer = styled("footer")(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
  textAlign: "center",
  backgroundColor: theme.palette.background.paper,
}));

const Home = () => {
  const [isFooterVisible, setIsFooterVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const fullHeight = document.documentElement.scrollHeight;

      if (scrollTop + windowHeight >= fullHeight) {
        setIsFooterVisible(true);
      } else {
        setIsFooterVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      style={{
        backgroundColor: theme.palette.background.default,
        padding: isSmallScreen ? "90px 20px 20px 20px" : "10px 20px 20px 20px",
      }}
    >
      <Container className="parent-container">
        <Box>
          <Box textAlign="center" my={4}>
            <img
              className="header-logo"
              src="/360_Logo_No4.png"
              alt="360 Family Counseling Business Logo"
            />
          </Box>
          <div className="content-padding">
            <Box className="welcome-section" my={4}>
              <Typography variant="h4" fontWeight="bold">
                Welcome
              </Typography>
            </Box>
            <Typography className="paragraph-text" paragraph>
              Starting counseling can be overwhelming. It takes courage to start
              your journey. Whether you are new to counseling or have
              participated in the past, I am happy that you have taken the steps
              to take care of yourself and your loved ones. We are happy to
              offer a safe place, one that is accepting, confidential and comes
              without bias. Whether the struggles include a loss, trauma, major
              life change, unwanted behavior or a lack of enjoyment, together I
              hope we can explore the obstacles and interferences that can
              sometimes keep us from being at peace.
            </Typography>
            <Box className="welcome-section" my={4}>
              <Typography variant="h4" fontWeight="bold">
                More About Us
              </Typography>
            </Box>
            <Typography className="paragraph-text" paragraph>
              360 Family Counseling provides support for individuals as well as
              Families. We have found in our work with individuals that
              oftentimes we are inviting a family member into our sessions.
              While this may not be the correct course for everyone, we found
              including loved ones in the therapeutic process can be powerful
              and healing for some. Family counseling can take on different
              forms. Including mother/daughter work, father/son work and even
              sibling work. At any age, we can struggle with important
              relationships. Family counseling can be a safe place to heal,
              improve relationships, sometimes make the difficult decision to
              end relationships, or even to set appropriate boundaries to live a
              new type of relationship. Family is not just our relatives. Many
              of us have adopted important people in our lives who make up our
              family. Other forms of family work can be strengthening family
              systems. Introducing structure, rules and reasonable expectations
              within the family home can help take the pressure off of parents
              and children alike. If a family is struggling with the dynamics in
              the home, family counseling can be a place where children, teens
              and parents can communicate without the fear of conflict but with
              the comfort of finally feeling heard. Sometimes parents/caregivers
              need support because they are tired of being the "bad guy."
              Sometimes teens need a voice when feeling misunderstood or when
              they feel they are treated unfairly and have to meet unreasonable
              expectations. Processing these issues in a safe environment can be
              helpful. Learning tools to continue with healthy communication
              benefits the home life. Including learning each other's conflict
              styles and communication styles. When working with children,
              parent/caregiver sessions are helpful. A child who is being seen
              for emotional or behavioral issues will learn tools and coping
              skills. It is important to meet with caregivers periodically to
              teach them the skills practiced in session in order for them to be
              supportive at home. These sessions also provide the
              parent/caregiver with skills and support.
            </Typography>
          </div>
          <Box className="bridge-section" my={4}>
            <img
              className="bridge-photo"
              src="/bridge.jpg"
              alt="Austin Texas 360 Bridge"
            />
          </Box>
          <Box className="services-section" my={4}>
            <Typography variant="h4" sx={{ paddingBottom: 2 }}>
              Our Services
            </Typography>
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} sm={6} md={5}>
                <Paper className="service" elevation={3} padding={2}>
                  <Typography variant="h5">
                    Adult Individual Counseling
                  </Typography>
                  <Typography>
                    Personalized support for your mental health journey.
                  </Typography>
                  <Link to="/About/services#individual-counseling">
                    Learn More
                  </Link>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <Paper className="service" elevation={3} padding={2}>
                  <Typography variant="h5">Family Counseling</Typography>
                  <Typography>
                    Strengthening family bonds and improving communication.
                  </Typography>
                  <Link to="/About/services#family-counseling">Learn More</Link>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <Paper className="service" elevation={3} padding={2}>
                  <Typography variant="h5">Couples Counseling</Typography>
                  <Typography>
                    Helping couples navigate their relationships.
                  </Typography>
                  <Link to="/About/services#couples-counseling">
                    Learn More
                  </Link>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <Paper className="service" elevation={3} padding={2}>
                  <Typography variant="h5">
                    Child and Adolescent Counseling
                  </Typography>
                  <Typography>
                    Support for younger individuals facing challenges.
                  </Typography>
                  <Link to="/About/services#child-adolescent-counseling">
                    Learn More
                  </Link>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <Paper className="service" elevation={3} padding={2}>
                  <Typography variant="h5">
                    Parenting and Co-Parenting
                  </Typography>
                  <Typography>
                    Connecting with others through shared experiences.
                  </Typography>
                  <Link to="/About/services#parenting-co-parenting">
                    Learn More
                  </Link>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          {/* ... other content ... */}
        </Box>
        <Footer className={isFooterVisible ? "visible" : ""}>
          <Typography>
            © 2024 360 Family Counseling PLLC. All rights reserved.
          </Typography>
        </Footer>
      </Container>
    </div>
  );
};

export default Home;

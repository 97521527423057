import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  Grid,
} from "@mui/material";
import "./index.css"; // Ensure you import the CSS file
import kidsAroundTable from "./assets/kids_around_table.png";
import coupleHiking from "./assets/images/couple_hiking.png";

const blogPosts = [
  {
    id: 1,
    title: "Supporting Young Children Through Divorce",
    content:
      "In my work in the family counseling setting, I have learned some valuable tips in helping children navigate divorce...",
    image: kidsAroundTable,
  },
  {
    id: 2,
    title: "Starting Couples Counseling",
    content:
      "Couples counseling can be a transformative experience for relationships facing challenges. This post delves into the key aspects of starting couples counseling, including how to choose the right therapist, setting realistic goals, and understanding the process to foster a healthier relationship...",
    image: coupleHiking,
  },
  // Add more blog posts here
];

const Blogs = () => {
  return (
    <Container>
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{ color: "inherit", textAlign: "center" }}
      >
        Blogs
      </Typography>
      <Grid container spacing={4} className="blog-grid">
        {blogPosts.map((post) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={post.id}
            className="blog-grid-item"
          >
            <Card className="blog-card">
              <CardMedia
                component="img"
                height="140"
                image={post.image}
                alt={post.title}
              />
              <CardContent>
                <Typography variant="h5" component="h3" gutterBottom>
                  {post.title}
                </Typography>
                <Typography variant="body2" component="p">
                  {post.content.substring(0, 150)}...
                </Typography>
                <Button
                  component={Link}
                  to={`/blog/${post.id}`}
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "16px" }}
                >
                  Read More
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Blogs;
